<template>
  <v-container fluid style="position: relative">
    <v-overlay v-if="showLoader" style="position: relative" color="transparent">
      <v-row justify="center" align-content="center" class="fill-height">
        <v-col class="text-center">
          <div class="display-3 mb-4" style="color: #000">Give us a second for Producer to do the producing bit</div>
          <img height="300" src="~/assets/svgs/loading/square.svg" />
          <div class="display-1 mt-4" style="color: #000">
            Shouldn’t be too long, but you can leave this page and get on with something else if you wish!
          </div>
          <div class="display-1" style="color: #000">Your video will continue to upload in the background.</div>
        </v-col>
      </v-row>
    </v-overlay>
    <v-row v-else>
      <v-col cols="12" md="6">
        <v-row class="d-flex flex-column fill-height px-4 px-md-2">
          <slot name="content" :playSection="playPart" :changeSource="changeVideoSource" />
          <v-spacer />
          <slot name="content-actions" />
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col class="py-0">
            <div style="position: relative">
              <div
                style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 2; pointer-events: none"
              >
                <slot name="effect-preview" />
              </div>
              <video-player
                ref="EditorVideo"
                :source="videoUrl"
                :countdown="3"
                :thumbnail="showThumbnail ? thumbnail : null"
                :status="videoStatus"
              />
            </div>
          </v-col>
        </v-row>
        <v-row
          :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
          :justify="$vuetify.breakpoint.smAndDown ? 'space-around' : 'end'"
          class="my-4 mx-1"
        >
          <slot name="actions" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import VideoPlayer from '../components/dashboard/VideoPlayer';

export default {
  name: 'VideoEdit',
  components: { VideoPlayer },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showThumbnail: true,
    };
  },
  computed: {
    ...mapFields('video', {
      video: 'current',
      videoPresent: 'current.url',
      videoStatus: 'current.status',
      thumbnail: 'current.thumbnail',
    }),
    videoStatus() {
      return this.video.status;
    },
    showLoader() {
      return !this.videoPresent || (this.videoStatus !== 'COMPLETED' && this.videoStatus !== 'FAILED');
    },
    videoUrl() {
      const url = this.video.subtitledUrl || this.video.url;
      const noPreviews = !this.video.previews.effects && !this.video.previews.subtitles;

      if (noPreviews) {
        return url;
      }
      if (this.type === 'EFFECTS') {
        return this.video.previews.effects || url;
      }
      if (this.type === 'SUBTITLES') {
        return this.video.previews.subtitles || url;
      }

      return url;
    },
  },
  methods: {
    playPart(startTime, endTime, event) {
      event.cancelBubble = true;

      this.$refs.EditorVideo.playPreview(startTime, endTime);
    },
    changeVideoSource(url) {
      this.$refs.EditorVideo.changeSource(url);
    },
  },
};
</script>

<style lang="scss">
.container.px-0 {
  padding: 0 !important;
}
</style>
