<template>
  <v-container class="fill-height" tag="section">
    <v-row class="text-center" justify="center">
      <v-col cols="auto">
        <!--<component :is="errorPage" :error="error" />-->
        <div class="public-page-title font-weight-black">{{ error.statusCode }}</div>
        <div class="display-3 white--text mb-5">{{ error.message }}</div>
        <div v-if="error.errorMessage" class="display-3 white--text mb-5 mt-10">Error: {{ error.errorMessage }}</div>
        <v-btn v-if="error.statusCode !== 410" :to="error.statusCode === 403 ? '/signin' : '/'" outlined>
          Get me out of here!
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ErrorLayout',
  layout: 'public',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred',
    };
  },
  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title,
    };
  },
  mounted() {
    console.log('Error passed to component >>>>>', this.error.errorMessage);
  },
};
</script>
