<template>
  <v-app>
    <pages-core-app-bar />
    <v-fade-transition mode="out-in">
      <pages-core-view />
    </v-fade-transition>
    <pages-core-footer color="transparent" />
  </v-app>
</template>

<script>
export default {
  name: 'PublicLayout',
  components: {
    PagesCoreAppBar: () => import('@/components/core/public/AppBar'),
    PagesCoreFooter: () => import('@/components/core/public/Footer'),
    PagesCoreView: () => import('@/components/core/public/View'),
  },
  created() {
    this.$vuetify.theme.dark = true;
  },
  beforeDestroy() {
    this.$vuetify.theme.dark = false;
  },
};
</script>
