<template>
  <v-app dark color="transparent">
    <v-app-bar app color="grey lighten-2" elevation="0">
      <img :src="client.bigLogo" height="40px" />
      <v-spacer />
      <v-toolbar-title>
        {{ client.name }}
      </v-toolbar-title>
    </v-app-bar>
    <v-content>
      <nuxt />
    </v-content>
    <video-preview />
    <pages-core-footer app absolute light color="transparent" />
    <notifications group="app" position="top right" />
  </v-app>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import VideoPreview from '../components/dashboard/VideoPreview';
import Intercom from '@/mixins/intercom';

export default {
  components: {
    VideoPreview,
    PagesCoreFooter: () => import('@/components/core/public/Footer'),
  },
  mixins: [Intercom],
  computed: {
    ...mapFields('video', {
      client: 'current.user.client',
    }),
  },
};
</script>
