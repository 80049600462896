import Vue from 'vue';
import * as clipboard from 'clipboard-polyfill';

const clipboardPlugin = {
  install(Vue, options) {
    Vue.$clipboard = clipboard;
    Vue.prototype.$clipboard = clipboard;
  },
};

Vue.use(clipboardPlugin);
