export default ({ $sentry, $config, store }) => {
  if ($sentry) {
    $sentry.configureScope(function (scope) {
      if (store.state.auth && store.state.auth.loggedIn) {
        scope.setUser({
          email: store.state.auth.user.email,
          username: store.state.auth.user.name,
          id: store.state.auth.user._id,
        });
      }
      scope.setExtra('config', $config);
      scope.setExtra('video-state', store.state.video);
      scope.setExtra('videos-state', store.state.videos);
      scope.setExtra('application-state', store.state.application);
    });
  }
};
