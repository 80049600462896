/* eslint-disable vue/no-unused-properties */
import Vue from 'vue';
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import { VHover, VListItem } from 'vuetify/lib';

Vue.mixin({
  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default,
              );
            },
          },
        });
      },
    },
  },
  /**
   * ensure globally mapped state is prefixed with _ to ensure distinction
   */
  computed: {
    ...mapState('application', {
      _appName: ['appName'],
    }),
    ...mapState('auth', {
      _user: ['user'],
    }),
    ...mapGetters('application', {
      _primaryGradient: 'primaryGradient',
      _secondaryGradient: 'secondaryGradient',
    }),
    _loading: {
      get() {
        return this.$store.state.application.loading;
      },
      set(val) {
        this.$store.commit('application/SET_LOADING', val);
      },
    },
  },
  mounted() {
    this.setActivity();
  },
  methods: {
    mapNavItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapNavItem) : undefined,
        title: this.$t(item.title),
      };
    },
    formatBytes(bytes, decimals) {
      if (bytes === 0 || !bytes) {
        return '0 Bytes';
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals || 2;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i];
    },
    formatDateCustom(date, pattern) {
      if (!date) return null;
      pattern = pattern || 'DD MMM YYYY';
      // return date ? new Date(date).toLocaleDateString('en-GB') : ''
      return moment(date).format(pattern);
    },
    formatDate(date) {
      if (!date) return null;
      // return date ? new Date(date).toLocaleDateString('en-GB') : ''
      return moment(date).format('DD MMM YYYY');
    },
    formatDateTime(date) {
      if (!date) return null;
      // return date ? new Date(date).toLocaleDateString('en-GB') : ''
      return moment(date).format('DD MMM YYYY HH:mma');
    },
    timeFromNow(date) {
      if (!date) return null;
      // return date ? new Date(date).toLocaleDateString('en-GB') : ''
      return moment(date).fromNow();
    },
    formatDuration(seconds, format) {
      if (!seconds) return seconds;
      // return date ? new Date(date).toLocaleDateString('en-GB') : ''
      return moment.utc(seconds * 1000).format(format || 'HH:mm:ss');
    },
    async setActivity() {
      const key = 'sentActivity';
      const sentActivity = localStorage.getItem(key);
      const today = moment().startOf('day').toString();

      if (sentActivity !== today) {
        localStorage.setItem(key, today);
        await this.$axios.$put(`${this.$config.producerApiEndpoint}/activity`);
      }
    },
  },
});
