<template>
  <v-badge :color="badgeColor" :content="badgeText" :value="badgeText" right overlap class="align-self-center">
    <v-tooltip v-if="toolTip" bottom>
      <template #activator="{ on }">
        <v-btn
          :outlined="buttonOutlined"
          :color="buttonColor"
          v-bind="$attrs"
          fab
          small
          v-on="on"
          @click="$emit('click', $event)"
        >
          <fa-icon :icon="buttonIcon" :spin="iconSpin" :pulse="iconPulse" size="2x"></fa-icon>
        </v-btn>
      </template>
      <span>{{ toolTip }}</span>
    </v-tooltip>
    <v-btn v-else :outlined="buttonOutlined" :color="buttonColor" fab small @click="$emit('click', $event)">
      <fa-icon :icon="buttonIcon" :spin="iconSpin" :pulse="iconPulse" size="2x"></fa-icon>
    </v-btn>
  </v-badge>
</template>

<script>
export default {
  props: {
    badgeColor: {
      type: String,
      default: 'primary',
    },
    badgeText: {
      type: [String, Number, Boolean],
      default: false,
    },
    buttonColor: {
      type: String,
      default: 'primary',
    },
    buttonIcon: {
      type: Array,
      default: undefined,
      required: true,
    },
    buttonOutlined: {
      type: Boolean,
      default: false,
    },
    toolTip: {
      type: String,
      default: undefined,
    },
    iconSpin: {
      type: Boolean,
      default: false,
    },
    iconPulse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
