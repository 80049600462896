<template>
  <v-form v-model="formValid" @submit.prevent="save">
    <v-text-field v-model="val" v-bind="{ ...$attrs }" :disabled="loading || isDisabled" @change="save">
      <template #append>
        <fa-icon :pulse="loading" :icon="appendIcon" size="lg"></fa-icon>
      </template>
      <template v-for="slot in Object.keys($slots)" #[slot]>
        <slot :name="slot" />
      </template>
    </v-text-field>
  </v-form>
</template>

<script>
import { has as _has } from 'lodash';

export default {
  name: 'BaseTextFieldAutoSave',
  props: {
    saveFn: {
      type: Function,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      undoValue: this.value,
      val: this.value,
      loading: false,
      formValid: false,
    };
  },
  computed: {
    isDisabled() {
      return _has(this.$attrs, 'disabled');
    },
    appendIcon() {
      if (this.isDisabled) return ['fad', 'empty-set'];
      else if (!this.formValid) return ['fal', 'exclamation-circle'];
      else if (this.loading) return ['fad', 'spinner'];
      else return ['fad', 'check'];
    },
  },
  methods: {
    async save(e) {
      if (this.formValid) {
        const newVal = e;
        this.loading = true;
        try {
          await this.saveFn(newVal);
          this.val = newVal;
          this.undoValue = newVal;
          this.$emit('input', newVal);
        } catch (e) {
          this.val = this.undoValue;
          this.$emit('input', this.undoValue);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
