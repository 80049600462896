import decode from 'jwt-decode';

export default async ({ $config, $auth, $axios }) => {
  let producerToken = $auth.$storage.getUniversal('producer');

  if (producerToken) {
    const currentTime = Date.now().valueOf() / 1000;
    const { exp } = decode(producerToken);

    $axios.interceptors.request.use(request => {
      if (currentTime < exp) {
        request.headers.common.Authorization = producerToken;
      } else {
        request.headers.common.Authorization = '';
      }
      return request;
    });
  }
  // We only want to request a producer JWT only when user is logged in from vision
  if (!$auth.loggedIn) {
    return;
  }

  if (!$auth.user.client.producerEnabled && $auth.ctx.route.name !== 'callback') {
    $auth.logout();
    $auth.redirect('noaccess');
    return;
  }

  if (!producerToken) {
    try {
      const { data: token } = await $axios.post(`${$config.producerApiEndpoint}/auth/login`);
      producerToken = `Bearer ${token}`;
      $auth.$storage.setUniversal('producer', producerToken);
      $axios.interceptors.request.use(request => {
        request.headers.common.Authorization = producerToken;
        return request;
      });
    } catch (error) {
      console.log('Error Fetching Bearer Token', error);
    }
  }
};
