import { getField, updateField } from 'vuex-map-fields';
import ImageType from '../../library/enums/ImageType';

export const state = () => ({
  companyImages: [],
  personalImages: [],
  companyCount: 0,
  personalCount: 0,
  query: {
    sortBy: 'createdAt',
    orderBy: -1,
    page: 1,
    pageSize: 21,
    searchTerm: '',
  },
  sortByOptions: [
    {
      text: 'Created Date',
      value: 'createdAt',
    },
    {
      text: 'Name',
      value: 'name',
    },
  ],
  orderByOptions: [
    {
      text: 'Ascending',
      value: 1,
    },
    {
      text: 'Descending',
      value: -1,
    },
  ],
});

export const getters = {
  getField,
};

export const mutations = {
  updateField,
  setImages(state, { images, type }) {
    switch (type) {
      case ImageType.COMPANY:
        state.companyImages = images;
        break;
      case ImageType.PERSONAL:
        state.personalImages = images;
        break;
      default:
        console.error(`Attempted to set Images but ${type} is not a valid Image Type`);
        break;
    }
  },
  setImageCount(state, { count, type }) {
    switch (type) {
      case ImageType.COMPANY:
        state.companyCount = count;
        break;
      case ImageType.PERSONAL:
        state.personalCount = count;
        break;
      default:
        console.error(`Attempted to set Image Count but ${type} is not a valid Image Type`);
        break;
    }
  },
  deleteImage(state, { type, id }) {
    switch (type) {
      case ImageType.COMPANY:
        state.companyImages = state.companyImages.filter(image => image._id !== id);
        state.companyCount = state.companyCount - 1;
        break;
      case ImageType.PERSONAL:
        state.personalImages = state.personalImages.filter(image => image._id !== id);
        state.personalCount = state.personalCount - 1;
        break;
      default:
        console.error(`Attempted to delete Image but ${type} is not a valid Image Type`);
        break;
    }
  },
};

export const actions = {
  async fetchImage(context, id) {
    try {
      return await this.$axios.$get(`${this.$config.producerApiEndpoint}/images/${id}`);
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error fetching image >>>>>', err);
      throw err;
    }
  },
  async getImages({ state, commit }) {
    try {
      const queryStr = new URLSearchParams(state.query).toString();
      const images = await this.$axios.$get(`${this.$config.producerApiEndpoint}/images?${queryStr}`);

      for (const type of Object.keys(ImageType)) {
        commit('setImages', { images: images[type].images, type });
        commit('setImageCount', { count: images[type].count, type });
      }
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error fetching images: ', err);
      throw err;
    }
  },
  async uploadImage(context, { image, type, filename, tags }) {
    const name = `${Date.now()}.png`;
    const formData = new FormData();
    formData.append('image', image, name);
    formData.append('type', type);
    formData.append('name', filename);
    formData.append('tags', tags.join(','));

    try {
      await this.$axios.$post(`${this.$config.producerApiEndpoint}/images`, formData);
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error uploading image: ', err);
      throw err;
    }
  },
  async deleteImage({ commit }, { id, type }) {
    try {
      await this.$axios.$delete(`${this.$config.producerApiEndpoint}/images/${id}`);
      commit('deleteImage', { id, type });
    } catch (err) {
      this.app.$sentry.captureException(err);
      const errorMessage = err?.response?.data?.message || err.message || err;
      console.log('Error deleting image: ', errorMessage);
      throw new Error(errorMessage);
    }
  },
};
