<template>
  <v-responsive :aspect-ratio="16 / 9" style="border-radius: 5px">
    <v-overlay v-if="status !== 'COMPLETED'" absolute style="text-align: center; align-items: flex-end">
      <svg width="30" height="10" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff">
        <circle cx="15" cy="15" r="15">
          <animate
            attributeName="r"
            from="15"
            to="15"
            begin="0s"
            dur="0.8s"
            values="15;9;15"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            from="1"
            to="1"
            begin="0s"
            dur="0.8s"
            values="1;.5;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="60" cy="15" r="9" fill-opacity="0.3">
          <animate
            attributeName="r"
            from="9"
            to="9"
            begin="0s"
            dur="0.8s"
            values="9;15;9"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            from="0.5"
            to="0.5"
            begin="0s"
            dur="0.8s"
            values=".5;1;.5"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="105" cy="15" r="15">
          <animate
            attributeName="r"
            from="15"
            to="15"
            begin="0s"
            dur="0.8s"
            values="15;9;15"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            from="1"
            to="1"
            begin="0s"
            dur="0.8s"
            values="1;.5;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
      <p class="mt-3">We are just getting that sorted for you...</p>
    </v-overlay>
    <div class="video-source" />
    <video ref="player" controls class="video-js vjs-default-skin vjs-16-9" playsinline crossorigin :poster="thumbnail">
      <source :src="source" controls type="video/mp4" />
    </video>
  </v-responsive>
</template>

<script>
const videojs = require('video.js').default;

export default {
  props: {
    source: {
      type: String,
      default: null,
    },
    shortId: {
      type: String,
      default: null,
    },
    record: {
      type: Boolean,
      default: false,
    },
    thumbnail: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
    pauseAfterScrubbing: Boolean,
    alwaysShowControls: Boolean,
  },
  data() {
    return {
      player: null,
      wasSeeking: false,
      playRecorded: false,
    };
  },
  watch: {
    status(newVal) {
      if (newVal === 'IN_PROGRESS' || newVal === 'QUEUED') {
        this.player.pause();
      }
    },
    source(newVal) {
      this.player.src(newVal);

      if (newVal.includes('/previews/')) {
        this.player.autoplay('play');
      }
    },
    thumbnail(newVal) {
      this.player.poster(newVal);
    },
  },
  async mounted() {
    this.player = await videojs(this.$refs.player, this.buildOptions());
    this.player.addClass('odro-theme');

    // only dispatch if there is a shortId - i.e. we are main view page
    if (this.shortId) {
      this.player.on('play', () => {
        if (this.playRecorded) {
          // only record the first play i.e. don't record play pressed after pause
          return;
        }
        this.$store.dispatch('video/logPlayEvent', { id: this.shortId });
        this.$store.dispatch('video/dispatchEvent', {
          viewerUserId: this.$auth?.user?._id,
          contextId: this.shortId,
          contextKey: 'PRODUCER',
          eventType: 'PLAY',
        });
        this.playRecorded = true;
      });
    }
    if (this.pauseAfterScrubbing) {
      this.player.on('play', () => {
        // HTML5 Video autoplays after seeking, so let's pause it again
        if (this.wasSeeking && !this.player.paused()) {
          this.player.pause();
          this.wasSeeking = false;
        }
      });
      this.player.on('pause', () => {
        // VideoJS fires pause event before seeking event.
        // However we can still check if the user is currently seeking
        // When the pause event is fired
        if (this.player.seeking()) this.wasSeeking = true;
      });
    }
  },
  methods: {
    /** @public */
    async setThumbnail() {
      const video = this.player.children_[0];
      const format = 'jpg';

      if (!video) {
        return false;
      }

      const canvas = document.createElement('CANVAS');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

      const dataUri = canvas.toDataURL('image/' + format);
      const data = dataUri.split(',')[1];
      const mimeType = dataUri.split(';')[0].slice(5);

      const bytes = window.atob(data);
      const buf = new ArrayBuffer(bytes.length);
      const arr = new Uint8Array(buf);

      for (let i = 0; i < bytes.length; i++) {
        arr[i] = bytes.charCodeAt(i);
      }

      const blob = new Blob([arr], { type: mimeType });
      try {
        await this.$store.dispatch('video/uploadThumbnail', {
          blob,
          metadata: JSON.stringify({
            width: video.videoWidth,
            height: video.videoHeight,
          }),
        });
      } catch (err) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Error!',
          text: `A problem occurred while trying to upload thumbnail: ${err.message}`,
          duration: 5000,
        });
      }
    },
    /** @public */
    changeSource(url) {
      this.player.src(url);
    },
    /** @public */
    playPreview(startTime, endTime) {
      this.player.off('timeupdate');

      this.player.currentTime(startTime);

      this.player.on('timeupdate', () => {
        const time = this.player.currentTime();

        if (time >= endTime) {
          this.player.off('timeupdate');
          this.player.pause();
        }
      });

      this.player.play();
    },
    buildOptions() {
      const options = {
        controls: true,
        autoplay: false,
        fluid: true,
        loop: false,
        controlBar: {
          volumePanel: true,
          pictureInPictureToggle: false,
        },
      };

      if (this.record) {
        options.plugins = {
          record: {
            audio: true,
            video: {
              width: 640,
              height: 360,
            },
            videoMimeType: 'video/webm;codecs=H264',
            maxLength: 30 * 60, // 30m
          },
        };
      }

      return options;
    },
    /** @public */
    isVisible(visible) {
      // When Video Play is made visible, add the classes to show the VideoJS controls
      if (this.alwaysShowControls && visible) {
        this.$nextTick(() => {
          this.player.addClass('vjs-has-started');

          const playButton = this.player.el_.querySelector('.vjs-play-control');
          playButton.classList.add('vjs-paused');
        });
      }
    },
  },
};
</script>

<style lang="scss">
.video-wrapper {
  border-radius: 7px;
}

.video-js {
  border-radius: 5px;

  &.odro-theme {
    & .vjs-control-bar {
      padding: 10px;
      background-color: transparent;
      height: 6em;

      & .vjs-play-control {
        border-radius: 50%;
        height: 35px;
        width: 35px;

        &.vjs-paused {
          background-color: green;
        }

        &.vjs-playing {
          background-color: orange;
        }

        &.vjs-ended {
          background-color: red;
        }

        & > .vjs-icon-placeholder {
          font-size: 1.2em;
        }
      }

      & .vjs-progress-control {
        height: 90%;
      }

      & .vjs-remaining-time {
        line-height: 3.4em;
      }
    }

    & .vjs-fullscreen-control {
      & .vjs-icon-placeholder {
        &:before {
          line-height: 2.2;
          font-size: 15px;
        }
      }
    }

    & .vjs-big-play-button {
      border: 0;
      font-size: 50px;
      height: 75px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 75px;
      transition: none;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 75px;
      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .video-js {
    height: auto !important;
  }

  .video-js.vjs {
    &-16-9 {
      padding-top: 0 !important;

      &.video-js.vjs-4-3 {
        &.video-js.vjs-fluid {
          height: auto !important;
        }
      }
    }
  }
}
</style>
