import Vue from 'vue';
import VueI18n from 'vue-i18n';

import ar from 'vuetify/lib/locale/ar';
import en from 'vuetify/lib/locale/en';

Vue.use(VueI18n);

export default ({ app, store }) => {
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  app.i18n = new VueI18n({
    locale: store.state.locale,
    fallbackLocale: 'en',
    messages: {
      en: {
        ...require('~/locales/en.json'),
        $vuetify: en,
      },
      'en-pirate': {
        ...require('~/locales/en-pirate.json'),
        $vuetify: en,
      },
      ar: {
        ...require('~/locales/ar.json'),
        $vuetify: ar,
      },
    },
  });

  app.i18n.path = link => {
    if (app.i18n.locale === app.i18n.fallbackLocale) {
      return `/${link}`;
    }

    return `/${app.i18n.locale}/${link}`;
  };
};
