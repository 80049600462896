// import Vuetify from '../../plugins/vendor/vuetify';
// import Vue from 'vue';
import { merge as _merge } from 'lodash';
import { nav as navigation } from './_navigation';

const DEFAULT_BRANDING_COLORS = {
  primary: '#E91E63',
  primaryAccent: '#FF4081',
  secondary: '#449BFF',
  secondaryAccent: '#40CFFF',
};

export const state = () => {
  return {
    appName: 'Odro Producer',
    loading: false,
    locales: {
      en: { name: 'English' },
      'en-pirate': { name: 'Pirate' },
      ar: { name: 'Arabic' },
    },
    locale: 'en',
    branding: {
      colors: DEFAULT_BRANDING_COLORS,
    },
    barColor: 'rgba(246,246,246, 1), rgba(246,246,246, .7)',
    drawer: undefined,
    isMini: undefined,
    navigation,
    whiteLabelDomain: undefined,
  };
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_LANG(state, locale) {
    if (state.locales[locale]) {
      state.locale = locale;
      this.app.i18n.locale = locale;
    }
  },
  SET_BRANDING_COLORS(state, colours) {
    // Vue.$logger.debug(Vuetify.framework.theme.isDark);
    // Vue.$logger.debug('Setting branding colors', colours);
    state.branding.colors = colours;
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload;
  },
  SET_MINI(state, payload) {
    state.isMini = payload;
  },
  SET_WHITELABEL_DOMAIN(state, payload) {
    state.whiteLabelDomain = payload;
  },
};

export const actions = {
  init({ dispatch }) {
    dispatch('setBrandingColors');
  },
  setLocale({ commit }, locale) {
    commit('SET_LANG', locale);
  },
  setBrandingColors({ commit }, { primary, primaryAccent, secondary, secondaryAccent } = {}) {
    // when we get a new settings object, update the branding colors
    const colors = _merge({}, DEFAULT_BRANDING_COLORS, { primary, primaryAccent, secondary, secondaryAccent });
    this.app.vuetify.framework.theme.themes.light.primary = colors.primary;
    this.app.vuetify.framework.theme.themes.dark.primary = colors.primary;
    this.app.vuetify.framework.theme.themes.light.secondary = colors.secondary;
    this.app.vuetify.framework.theme.themes.dark.secondary = colors.secondary;
    this.app.vuetify.framework.theme.themes.light.accent = colors.secondaryAccent;
    this.app.vuetify.framework.theme.themes.dark.accent = colors.secondaryAccent;

    commit('SET_BRANDING_COLORS', colors);
  },
  setWhitelabelDomain({ commit }, whiteLabelDomain) {
    commit('SET_WHITELABEL_DOMAIN', whiteLabelDomain);
  },
};

export const getters = {
  primaryGradient(state) {
    return `background: linear-gradient(87deg,${state.branding.colors.primary},${state.branding.colors.primaryAccent})`;
  },
  secondaryGradient(state) {
    return `background: linear-gradient(87deg,${state.branding.colors.secondary},${state.branding.colors.secondaryAccent})`;
  },
  dashboardNav(state) {
    return state.navigation.filter(item => !item.meta.public);
  },
  footerNav(state) {
    return state.navigation.filter(item => item.meta && item.meta.public && item.meta.publicFooterNav);
  },
  publicNav(state) {
    return state.navigation.filter(item => item.meta && item.meta.public && item.meta.publicNav);
  },
  whiteLabelDomain(state) {
    return state.whiteLabelDomain;
  },
};
