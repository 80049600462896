import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2956b301 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _227baf81 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _30f2cb14 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _4dad550a = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _0b652f20 = () => interopDefault(import('../pages/healthcheck.vue' /* webpackChunkName: "pages/healthcheck" */))
const _3d482da1 = () => interopDefault(import('../pages/noaccess.vue' /* webpackChunkName: "pages/noaccess" */))
const _48e76997 = () => interopDefault(import('../pages/videos/index.vue' /* webpackChunkName: "pages/videos/index" */))
const _5ac5dea4 = () => interopDefault(import('../pages/videos/video/_id/index.vue' /* webpackChunkName: "pages/videos/video/_id/index" */))
const _3d588a15 = () => interopDefault(import('../pages/v/_id.vue' /* webpackChunkName: "pages/v/_id" */))
const _490e8dc6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _2956b301,
    name: "about"
  }, {
    path: "/callback",
    component: _227baf81,
    name: "callback"
  }, {
    path: "/contact",
    component: _30f2cb14,
    name: "contact"
  }, {
    path: "/faq",
    component: _4dad550a,
    name: "faq"
  }, {
    path: "/healthcheck",
    component: _0b652f20,
    name: "healthcheck"
  }, {
    path: "/noaccess",
    component: _3d482da1,
    name: "noaccess"
  }, {
    path: "/videos",
    component: _48e76997,
    name: "videos"
  }, {
    path: "/videos/video/:id",
    component: _5ac5dea4,
    name: "videos-video-id"
  }, {
    path: "/v/:id?",
    component: _3d588a15,
    name: "v-id"
  }, {
    path: "/",
    component: _490e8dc6,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
