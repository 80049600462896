<template>
  <v-hover>
    <template #default="{ hover }">
      <v-card v-bind="$attrs" :outlined="!noOutline" class="flex-card" :title="tooltip">
        <div v-if="avatar" :style="{ height: avatarSize / 2 + 'px' }" class="d-flex">
          <v-avatar :size="avatarSize" class="mx-auto v-card--material__avatar elevation-10" color="grey">
            <v-img :src="avatar">
              <v-row v-if="$slots['avatar-action']" align="center" class="fill-height">
                <v-col>
                  <slot name="avatar-action" />
                </v-col>
              </v-row>
            </v-img>
          </v-avatar>
        </div>
        <slot name="image" />
        <v-toolbar v-if="title || $slots['toolbar-actions']" :dense="dense" color="transparent" flat>
          <v-toolbar-title v-if="title" class="display-2 py-3 mb-0" v-text="title" />
          <slot name="toolbar-actions" />
        </v-toolbar>
        <v-divider v-if="title || $slots['toolbar-actions']"></v-divider>
        <v-card-subtitle v-if="subTitle" class="display-1 pb-2" v-text="subTitle" />
        <slot name="prepend-default" />
        <v-card-text :class="noPadding && 'pa-0'" class="grow">
          <slot />
        </v-card-text>
        <slot name="append-default" />
        <v-card-actions v-if="$slots.actions" class="mx-2 pa-2">
          <slot name="actions" />
        </v-card-actions>
        <slot :hover="hover" name="hover-overlay" />
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  name: 'BaseMaterialCard',
  props: {
    title: {
      type: String,
      default: undefined,
    },
    subTitle: {
      type: String,
      default: undefined,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    noOutline: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: String,
      default: '',
    },
    avatarSize: {
      type: Number,
      default: 130,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="sass">
.v-card--material
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px
</style>
