import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// todo: we should only import the icons we're using
// https://github.com/FortAwesome/vue-fontawesome
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas);
library.add(far);
library.add(fal);
library.add(fad);
library.add(fab);

Vue.component('FaIcon', FontAwesomeIcon); // Register component globally
// Vue.component('font-awesome-icon', FontAwesomeIcon); // Register component globally
