/**
 *
 * Static representation of routes to drive the navigation
 *
 * The route permissions and metadata are managed centrally here because the Nuxt implementation of
 * route meta data is incomplete and doesn't populate the this.$route.meta object anywhere other
 * than middleware... so its not able to generate a nav or apply permissions efficiently
 *
 * All routes are assumed private - to allow public access set meta on the page / layout
 *
 */
const nav = [
  {
    icon: ['far', 'film'],
    title: 'videos',
    to: '/videos',
  },
  {
    icon: ['far', 'question-square'],
    title: 'faq',
    to: '/faq',
  },
  {
    icon: ['far', 'info-circle'],
    title: 'about',
    to: '/about',
  },
  {
    icon: ['fal', 'sign-in'],
    title: 'sign in',
    to: '/',
    meta: {
      public: true,
      publicNav: true,
    },
  },
];

// doesn't include pages with params as they're no in the array above
// ... this is taken care of in the extendRoutes logic
const _flattenNav = (items, parentPath, parentMeta) => {
  return items.reduce((result, i) => {
    const itemPath = i.to || i.group;
    // mutate the original nav object to now include the parent meta / path - useful by-product
    // use parent metadata if nothing specified on children
    i.meta = i.meta || parentMeta || false;
    i.path = parentPath ? `${parentPath}${itemPath}` : itemPath;
    if (i.children) {
      result = result.concat(_flattenNav(i.children, i.path, i.meta));
    }
    // only push things that have a router entry / proper path
    i.to && result.push(i);
    return result;
  }, []);
};

const flatNav = _flattenNav(nav);

module.exports = { nav, flatNav };
