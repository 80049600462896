import { getField } from 'vuex-map-fields';

const defaultState = () => {
  return {
    _id: null,
    user_id: null,
    name: '',
    email: '',
    client: {
      _id: null,
      name: '',
      bigLogo: '',
      footerLogo: '',
    },
    accessLevel: '',
    phone: null,
    profilePicture: '',
  };
};
export const state = defaultState;

export const getters = {
  getField,
};

export const mutations = {
  set(state, user) {
    if (!user) {
      return Object.assign(state, defaultState());
    }
    if (!user._id) {
      user._id = user.user_id;
    }
    Object.assign(state, user);
  },
};
