export const state = () => {
  return {};
};

const addAuthUserToStore = store =>
  store.subscribe(mutation => {
    if (mutation.type === 'auth/SET' && mutation.payload.key === 'user') {
      store.commit('user/set', mutation.payload.value, { root: true });
    }
  });

const setAuthWithDynamicEnvs = store =>
  store.subscribe(mutation => {
    if (mutation.type === 'auth/SET' && mutation.payload.key === 'strategy' && mutation.payload.value === 'vision') {
      const config = store.$config;
      const visionStrategy = store.$auth.strategies.vision;

      visionStrategy.options = {
        ...visionStrategy.options,
        authorization_endpoint: `${config.visionApiEndpoint}/oauth/dialog/authorize`,
        userinfo_endpoint: `${config.visionApiEndpoint}/oauth/userinfo`,
        redirect_uri: `${config.baseUrl}/callback`,
        client_id: config.oauthClientId,
      };
    }
  });

export const plugins = [addAuthUserToStore, setAuthWithDynamicEnvs];
