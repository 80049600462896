import { setIntervalAsync, clearIntervalAsync } from 'set-interval-async/dynamic';
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash';
import Vue from 'vue';

const TranscriptStatus = {
  QUEUED: 'QUEUED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

const JobStatus = {
  QUEUED: 'QUEUED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

let jobPoller;
let transcriptPoller;

export const state = () => {
  return {
    previewInProgress: false,
    current: null,
    tagLibrary: null,
  };
};

export const getters = {
  getField,
};

export const mutations = {
  updateField,
  setStatus(state, status) {
    state.current.status = status;
  },
  setPreviewInProgress(state, inProgress) {
    state.previewInProgress = inProgress;
  },
  addEffect(state, effect) {
    const currentEffects = state.current.effects;

    if (effect.type === 'intro') state.current.effects = [effect, ...currentEffects];
    else if (effect.type === 'outro') state.current.effects = [...currentEffects, effect];
    else {
      const outro = currentEffects.find(e => e.type === 'outro');
      if (outro) state.current.effects.splice(currentEffects.length - 1, 0, effect);
      else state.current.effects.push(effect);
    }
  },
  removeEffect(state, index) {
    state.current.effects.splice(index, 1);
  },
  removeAllEffects(state) {
    state.current.effects = [];
  },
  set(state, video) {
    state.current = video;
  },
  setTagLibrary(state, library) {
    state.tagLibrary = library;
  },
  updateEffect(state, data) {
    Vue.set(state, `current.effects[${data.index}]`, data.value);
  },
  updateEffectValue(state, data) {
    _.set(state.current.effects[data.index], data.path, data.value);
  },
  updateTranscript(state, transcript) {
    state.current.transcript = transcript;
  },
};

export const actions = {
  async upload(context, video) {
    window.$nuxt.$loading.start();
    let data;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        window.$nuxt.$loading.increase(percentCompleted, true);
      },
    };
    const formData = new FormData();
    formData.append('video', video, video.name);
    if (video.name) {
      formData.append('name', video.name);
    }
    formData.append('thumbnail', 'true');
    try {
      data = await this.$axios.$post(`${this.$config.producerApiEndpoint}/videos`, formData, config);
      window.$nuxt.$loading.finish();
      return data._id;
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error uploading a video >>>>>', err);
      throw err;
    }
  },
  async fetch(context, videoId) {
    return await this.$axios.$get(`${this.$config.producerApiEndpoint}/videos/${videoId}`);
  },
  async fetchPublished(context, videoId) {
    const response = await this.$axios.$get(`${this.$config.producerApiEndpoint}/videos/published/${videoId}`);

    if (!response) {
      this.app.$sentry.captureException(response);
      return;
    }

    return response;
  },
  async update({ state, commit }) {
    try {
      const data = await this.$axios.$put(`${this.$config.producerApiEndpoint}/videos/${state.current._id}`, {
        metadata: state.current.metadata,
        isPublished: state.current.isPublished,
        displayContactDetails: state.current.displayContactDetails,
        links: state.current.links,
        firstPublishDate: state.current.firstPublishDate,
      });
      commit('set', data);
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error trying to update video >>>>>', err);
      throw err;
    }
  },
  async uploadThumbnail({ state, dispatch }, data) {
    let videoId;

    if (data.id) {
      videoId = data.id;
    } else {
      videoId = state.current._id;
    }

    const formData = new FormData();
    formData.append('image', data.blob, 'thumbnail.jpeg');
    formData.append('metadata', data.metadata);

    try {
      const response = await this.$axios.$post(
        `${this.$config.producerApiEndpoint}/videos/${videoId}/thumbnail`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      if (response.key) {
        await dispatch('setState', state.current._id);
      }
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error uploading thumbnail >>>>>', err);
      throw err;
    }
  },
  async addEffects({ state, dispatch, commit }, isPreview) {
    try {
      const data = await this.$axios.$post(`${this.$config.producerApiEndpoint}/videos/${state.current._id}/effects`, {
        effects: state.current.effects,
        isPreview,
      });

      commit('setStatus', data.status);

      if (data.status !== JobStatus.COMPLETED) {
        dispatch('watchJobStatus', data._id);
      } else {
        await dispatch('setState', data._id);
      }
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error while trying to add effects >>>>>', err);
      throw err;
    }
  },
  async addSubtitles({ state, dispatch, commit }, isPreview) {
    try {
      const data = await this.$axios.$post(
        `${this.$config.producerApiEndpoint}/videos/${state.current._id}/subtitles`,
        {
          transcript: state.current.transcript,
          isPreview,
        },
      );

      commit('setStatus', data.status);

      if (data.status !== JobStatus.COMPLETED) {
        dispatch('watchJobStatus', data._id);
      } else {
        await dispatch('setState', data._id);
      }
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error while trying to add subtitles >>>>>', err);
      throw err;
    }
  },
  watchJobStatus({ rootState, state, commit, dispatch }, videoId) {
    dispatch('stopJobPoller'); // Making sure any other job pollers are stopped before we start another.
    if (!state.current && videoId) return;
    jobPoller = setIntervalAsync(async () => {
      let video;
      try {
        if (rootState.auth.loggedIn) {
          video = await dispatch('fetch', videoId);
        } else {
          video = await dispatch('fetchPublished', videoId);
        }
      } catch (err) {
        this.app.$sentry.captureException(err);
        console.log('Error trying to fetch video >>>>>', err);
        Vue.notify({
          group: 'app',
          type: 'error',
          title: 'Error!',
          text: `A problem occurred while polling for video status: ${err.message}`,
          duration: 5000,
        });
        return;
      }

      const previewInProgress =
        typeof video.previews.effects === 'string' || typeof video.previews.subtitles === 'string';

      commit('setPreviewInProgress', previewInProgress);
      commit('setStatus', video.status);

      switch (video.status) {
        case JobStatus.COMPLETED:
          commit('set', video);
          await dispatch('stopJobPoller');
          break;
        case JobStatus.FAILED:
          commit('set', video);
          await dispatch('stopJobPoller');
          Vue.notify({
            group: 'app',
            type: 'error',
            title: 'Error!',
            text: 'Something seems to have gone wrong, If this happens again please contact support.',
            duration: 10000,
          });
          break;
      }
    }, 1000);
  },
  stopJobPoller() {
    if (jobPoller) {
      clearIntervalAsync(jobPoller);
      jobPoller = undefined;
    }
  },
  async uploadAsset({ state }, image) {
    const name = `${Date.now()}.png`;
    const formData = new FormData();
    formData.append('image', image, name);
    try {
      return await this.$axios.$post(`${this.$config.producerApiEndpoint}/videos/${state.current._id}/asset`, formData);
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error fetching images >>>>>', err);
      throw err;
    }
  },
  async setState({ rootState, dispatch, commit }, videoId) {
    let video;
    try {
      if (rootState.auth.loggedIn) {
        video = await dispatch('fetch', videoId);
        if (!video) return;
      } else {
        video = await dispatch('fetchPublished', videoId);
        if (!video) return;
      }
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error trying to fetch video >>>>>', err);
      throw err;
    }
    // Force Browser and Vue to update thumbnail
    const randomLetter = String.fromCharCode(97 + Math.floor(Math.random() * 26));
    const randomNumber = Math.floor(Math.random() * 999999);
    video.thumbnail += `?${randomLetter}=${randomNumber}`;

    commit('set', video);

    if (process.client) {
      if (video.status !== JobStatus.COMPLETED) {
        dispatch('watchJobStatus', video._id);
      }
    }

    return video;
  },
  clearState({ commit, dispatch }) {
    commit('set', null);
    if (jobPoller) {
      dispatch('stopJobPoller');
    }
    if (transcriptPoller) {
      dispatch('stopTranscriptPoller');
    }
  },
  watchForVideoUrl({ state, dispatch, commit }, videoId) {
    const poller = setIntervalAsync(async () => {
      if (state.current === null) {
        clearIntervalAsync(poller);
      }

      const video = await dispatch('fetch', videoId);

      if (video.url) {
        commit('set', video);
        clearIntervalAsync(poller);
      }
    }, 1000);
  },
  watchTranscriptionJob({ state, dispatch, commit }, videoId) {
    dispatch('stopTranscriptPoller'); // Making sure any other transcript pollers are stopped before we start another.
    transcriptPoller = setIntervalAsync(async () => {
      if (state.current === null) {
        clearIntervalAsync(transcriptPoller);
      }

      let data;
      try {
        data = await dispatch('fetch', videoId);
      } catch (err) {
        this.app.$sentry.captureException(err);
        console.log(err);

        Vue.notify({
          group: 'app',
          type: 'error',
          title: 'Error!',
          text: `A problem occurred while polling for transcription status: ${err.message}`,
          duration: 5000,
        });
        return;
      }
      if (data.transcript) {
        const status = data.transcript.status;

        switch (status) {
          case TranscriptStatus.COMPLETED:
            commit('updateTranscript', data.transcript);
            dispatch('stopTranscriptPoller');
            return;
          case TranscriptStatus.FAILED:
            commit('updateTranscript', data.transcript);
            dispatch('stopTranscriptPoller');
        }
      }
    }, 5000);
  },
  stopTranscriptPoller() {
    if (transcriptPoller) {
      clearIntervalAsync(transcriptPoller);
      transcriptPoller = undefined;
    }
  },
  async setTagLibrary({ commit }) {
    try {
      const data = await this.$axios.$get(`${this.$config.producerApiEndpoint}/videos/tags/library`);
      commit('setTagLibrary', data);
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error setting tags library >>>>>', err);
      throw err;
    }
  },
  async delete(context, videoId) {
    try {
      return await this.$axios.$delete(`${this.$config.producerApiEndpoint}/videos/${videoId}`);
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error deleting a video >>>>>', err);
      throw err;
    }
  },
  async getSignedUrl(context, data) {
    try {
      return await this.$axios.$get(
        `${this.$config.producerApiEndpoint}/videos/signedUrl/${data.videoId}?preview=${data.isPreview}`,
      );
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error fetching signedUrl >>>>>', err);
      throw err;
    }
  },

  async dispatchEvent(context, { viewerUserId, contextId, contextKey, eventType }) {
    try {
      return await this.$axios.$post(`${this.$config.visionApiEndpoint}/api/v1/event`, {
        viewerUserId,
        contextId,
        contextKey,
        eventType,
      });
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log(`Error posting ${eventType} event >>>>>`, err);
      throw err;
    }
  },
  async logPlayEvent(context, data) {
    try {
      return await this.$axios.$post(`${this.$config.producerApiEndpoint}/videos/${data.id}/played`);
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error logging play event >>>>>', err);
      throw err;
    }
  },
  async logViewEvent(context, data) {
    try {
      return await this.$axios.$post(`${this.$config.producerApiEndpoint}/videos/${data.id}/viewed`);
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error logging play event >>>>>', err);
      throw err;
    }
  },
};
