import { clearIntervalAsync, setIntervalAsync } from 'set-interval-async/dynamic';
import { getField, updateField } from 'vuex-map-fields';
import Vue from 'vue';

export const state = () => ({
  currentPageSize: 24,
  query: {
    showAll: false,
    sortBy: 'createdAt',
    orderBy: -1,
    page: 1,
    pageSize: 24,
    searchTerm: '',
  },
  sortByOptions: [
    {
      text: 'Created Date',
      value: 'createdAt',
    },
    {
      text: 'Updated Date',
      value: 'updatedAt',
    },
    {
      text: 'Title',
      value: 'metadata.name',
    },
  ],
  orderByOptions: [
    {
      text: 'Ascending',
      value: 1,
    },
    {
      text: 'Descending',
      value: -1,
    },
  ],
  pageSizes: [24, 48, 96, 192],
  count: 0,
  videos: [],
});

export const getters = {
  getField,
};

export const mutations = {
  updateField,
  setVideos(state, videos) {
    state.videos = videos;
  },
  setCount(state, count) {
    state.count = count;
  },
  setNewVideoToList(state, video) {
    const videos = [...state.videos];
    videos[video.index] = video.obj;
    state.videos = videos;
  },
};

export const actions = {
  async fetchVideos({ state, commit }) {
    const queryStr = new URLSearchParams(state.query).toString();
    let data;

    try {
      data = await this.$axios.$get(`${this.$config.producerApiEndpoint}/videos?${queryStr}`);
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error loading videos for list page >>>>>', err);
      throw err;
    }

    commit('setCount', data.count);
    commit('setVideos', data.videos);
  },
  watchForVideoUrls({ state, dispatch, commit }) {
    const poller = setIntervalAsync(async () => {
      const videosNotReady = [];
      for (let i = 0; i < state.videos.length; i++) {
        if (!state.videos[i].url && state.videos[i].status !== 'COMPLETED' && state.videos[i].status !== 'FAILED') {
          videosNotReady.push({ index: i, obj: state.videos[i] });
        }
      }
      if (videosNotReady.length) {
        for (const video of videosNotReady) {
          let returnedVideo;
          try {
            returnedVideo = await dispatch('video/fetch', video.obj._id, { root: true });
          } catch (err) {
            this.app.$sentry.captureException(err);
            console.log(err);

            Vue.notify({
              group: 'app',
              type: 'error',
              title: 'Error!',
              text: `A problem occurred while polling for video status: ${err.message}`,
              duration: 5000,
            });
            return;
          }
          if (returnedVideo.url) {
            video.obj = returnedVideo;
            commit('setNewVideoToList', video);
          }
        }
      }

      if (videosNotReady.length === 0) {
        await clearIntervalAsync(poller);
      }
    }, 5000);
  },
  async deleteVideo(context, videoId) {
    try {
      return await this.$axios.$delete(`${this.$config.producerApiEndpoint}/videos/${videoId}`);
    } catch (err) {
      this.app.$sentry.captureException(err);
      console.log('Error fetching images >>>>>', err);
      throw err;
    }
  },
  async copyVideo(context, videoId) {
    return await this.$axios.$post(`${this.$config.producerApiEndpoint}/videos/${videoId}/copy`);
  },
};
