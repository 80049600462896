const routeOption = (route, key, value) => {
  return route.matched.some(m => {
    if (process.client) {
      // Client
      return Object.values(m.components).some(component => component.options && component.options[key] === value);
    } else {
      // SSR
      return Object.values(m.components).some(component =>
        Object.values(component._Ctor).some(ctor => ctor.options && ctor.options[key] === value),
      );
    }
  });
};

export default ({ $auth, $axios, $config }) => {
  // We only want this handling to occur once logged in axios requests
  $axios.onRequest(config => {
    console.log('Making request to ' + config.url);
  });

  $axios.onError(async err => {
    console.log('Error', err);
    const code = parseInt(err.response && err.response.status);
    console.log('error', code, err.response);
    if ($auth.loggedIn) {
      if (code === 401) {
        // TODO: This still seems to keep a session with vision, need to remove somehow
        // Logout the user if any axios request returns a 401 status
        const currentStrategy = $auth.$storage.getState('strategy');
        const visionToken = $auth.$storage.getState(`_token.${currentStrategy}`);
        await $axios.get(`${$config.visionApiEndpoint}/oauth/logout`, {
          headers: { Authorization: visionToken },
        });
        // console.log(logoutResponse.config.headers)

        $auth.$storage.removeUniversal('producer');
        await $auth.logout();

        // this part is not set for server side in the auth module v4.91 so needs added here
        if (process.server) {
          if (!routeOption($auth.ctx.route, 'auth', false)) {
            $auth.redirect($auth.loggedIn ? 'home' : 'logout');
          }
        }
        return;
      }
    }
    throw err;
  });
};
