import { render, staticRenderFns } from "./VideoPreview.vue?vue&type=template&id=15bf7eb9&scoped=true&"
import script from "./VideoPreview.vue?vue&type=script&lang=js&"
export * from "./VideoPreview.vue?vue&type=script&lang=js&"
import style0 from "./VideoPreview.vue?vue&type=style&index=0&id=15bf7eb9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15bf7eb9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/@nuxtjs/vuetify/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBtn,VCard,VCardActions,VDialog})
