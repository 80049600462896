<template>
  <v-overlay :value="loading" absolute z-index="99">
    <v-dialog v-model="loading" persistent max-width="350">
      <v-card class="d-flex flex-column align-center justify-center card">
        <v-icon v-if="!visible" class="loader mt-3">mdi-loading</v-icon>
        <v-card-text class="text-center mt-3">We are just sorting that for you!</v-card-text>
        <v-progress-linear v-if="visible" v-model="percentage" class="progress" height="15">
          <strong>{{ percentage }}%</strong>
        </v-progress-linear>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    percentage: 0,
    visible: false,
  }),
  methods: {
    /** @public */
    start() {
      this.loading = true;
    },
    /** @public */
    finish() {
      this.visible = false;
      this.loading = false;
    },
    /** @public */
    increase(num, visible = false) {
      this.visible = visible;
      this.percentage = num;
    },
  },
};
</script>

<style scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  font-size: 6rem !important;
  color: rgb(233, 30, 99) !important;
  animation: spin 2s linear infinite;
}

.progress {
  color: #fff !important;
}

.card {
  width: 100%;
}
</style>
