<template>
  <v-app>
    <dashboard-core-app-bar></dashboard-core-app-bar>
    <dashboard-core-drawer></dashboard-core-drawer>
    <div :style="_primaryGradient" class="header-container">
      <div class="separator">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 150"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon :class="$vuetify.theme.dark ? 'fill-dark' : 'fill-light'" points="2560 0 2560 150 0 150" />
        </svg>
      </div>
    </div>
    <v-content class="hoik-up transparent">
      <v-fade-transition mode="out-in">
        <nuxt />
      </v-fade-transition>
    </v-content>
    <video-preview />
    <notifications group="app" position="top right" />
    <dashboard-core-footer />
  </v-app>
</template>

<script>
import Intercom from '@/mixins/intercom';
export default {
  name: 'DashboardLayout',
  components: {
    DashboardCoreAppBar: () => import('@/components/core/dashboard/appBar/Index'),
    DashboardCoreDrawer: () => import('@/components/core/dashboard/drawer/Index'),
    DashboardCoreFooter: () => import('@/components/core/dashboard/Footer'),
    VideoPreview: () => import('@/components/dashboard/VideoPreview'),
  },
  mixins: [Intercom],
};
</script>

<style lang="sass" scoped>
// diagonal header styles
.header-container
  position: relative
  height: 350px
.separator
  bottom: 0
  height: 70px
  position: absolute
  top: auto
  right: 0
  left: 0
  transform: matrix(1, 0, 0, 1, 0, 0)
  svg
    bottom: 0
    position: absolute
.fill-light
  fill: #f8f9fe
.fill-dark
  fill: #303030
.hoik-up
  margin-top: -350px
.odro-scroll::-webkit-scrollbar-track
  margin-top: 10px
  border-radius: 10px
  background-color: transparent
.odro-scroll::-webkit-scrollbar
  width: 5px
  background-color: transparent
.odro-scroll::-webkit-scrollbar-thumb
  border-radius: 10px
  background-color: #e91e63
</style>
