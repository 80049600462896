export default {
  async mounted() {
    if (this._user) {
      const currentStrategy = this.$auth.$storage.getState('strategy');
      const visionToken = this.$auth.$storage.getState(`_token.${currentStrategy}`);

      if (visionToken) {
        const intercomData = await this.$axios.get(`${this.$config.visionApiEndpoint}/api/v1/intercom/data`, {
          headers: { Authorization: visionToken },
          params: { id: this._user._id },
        });

        this.$intercom.boot({
          ...intercomData.data,
        });
      }
    }
  },
  beforeDestory() {
    this.$intercom.shutdown();
  },
};
