<template>
  <v-dialog v-model="visible" class="elevation-0" max-width="960px" @click:outside="close()">
    <v-card class="elevation-0 flex-column justify-center align-center" color="transparent">
      <video-player
        ref="Video"
        class="video-preview"
        :source="source"
        :thumbnail="thumbnail"
        :pause-after-scrubbing="selectThumbnail"
        :always-show-controls="selectThumbnail"
        :status="status"
      />
      <v-card-actions style="padding: 0; margin: 0">
        <div
          v-if="selectThumbnail"
          class="d-flex justify-center align-center flex-column flex-md-row"
          style="background-color: white; padding: 15px; width: 100%"
        >
          <p style="color: black; line-height: 2" class="mr-5 mb-0">
            Move the scrubber to select a new thumbnail, once you are happy click the “select this frame” button.
          </p>
          <v-btn color="primary" @click="select()">Select this frame</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VideoPlayer from './VideoPlayer';

export default {
  name: 'VideoPreview',
  components: { VideoPlayer },
  data() {
    return {
      source: null,
      thumbnail: null,
      visible: false,
      selectThumbnail: false,
      status: null,
    };
  },
  watch: {
    visible(isVisible) {
      this.$nextTick(() => {
        this.$refs.Video.isVisible(isVisible);
      });
    },
  },
  created() {
    this.$nuxt.$on('show-preview', async (video, options = {}) => {
      const { selectThumbnail = false } = options;

      try {
        this.source = await this.$store.dispatch('video/getSignedUrl', { videoId: video._id, isPreview: false });
      } catch (err) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Error!',
          text: `A problem occurred while loading videos for list page: ${err.message}`,
          duration: 5000,
        });
        return;
      }

      this.selectThumbnail = selectThumbnail;
      this.thumbnail = video.thumbnail;
      this.visible = true;
      this.status = video.status;
    });
  },
  methods: {
    close() {
      this.$refs.Video.player.pause();
      this.visible = false;
    },
    async select() {
      await this.$refs.Video.setThumbnail();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  box-shadow: none !important;
}
</style>
