import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=646f4220&scoped=true&"
import script from "./dashboard.vue?vue&type=script&lang=js&"
export * from "./dashboard.vue?vue&type=script&lang=js&"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=646f4220&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "646f4220",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/@nuxtjs/vuetify/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContent } from 'vuetify/lib/components/VContent';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VApp,VContent,VFadeTransition})
