import { getField, updateField } from 'vuex-map-fields';
import TemplateType from '../../library/enums/TemplateType';

export const state = () => {
  return {
    companyTemplates: [],
    personalTemplates: [],
    companyCount: 0,
    personalCount: 0,
    query: {
      sortBy: 'createdAt',
      orderBy: -1,
      page: 1,
      pageSize: 24,
      searchTerm: '',
    },
    sortByOptions: [
      {
        text: 'Created Date',
        value: 'createdAt',
      },
      {
        text: 'Name',
        value: 'name',
      },
    ],
    orderByOptions: [
      {
        text: 'Ascending',
        value: 1,
      },
      {
        text: 'Descending',
        value: -1,
      },
    ],
  };
};

export const getters = {
  getField,
};

export const mutations = {
  updateField,
  setTemplates(state, { templates, type }) {
    switch (type) {
      case TemplateType.COMPANY:
        state.companyTemplates = templates;
        break;
      case TemplateType.PERSONAL:
        state.personalTemplates = templates;
        break;
      default:
        console.error(`Attempted to set Templates but ${type} is not a valid Template Type`);
        break;
    }
  },
  setTemplateCount(state, { count, type }) {
    switch (type) {
      case TemplateType.COMPANY:
        state.companyCount = count;
        break;
      case TemplateType.PERSONAL:
        state.personalCount = count;
        break;
      default:
        console.error(`Attempted to set Template Count but ${type} is not a valid Template Type`);
        break;
    }
  },
  deleteTemplate(state, { type, id }) {
    switch (type) {
      case TemplateType.COMPANY:
        state.companyTemplates = state.companyTemplates.filter(template => template._id !== id);
        state.companyCount = state.companyCount - 1;
        break;
      case TemplateType.PERSONAL:
        state.personalTemplates = state.personalTemplates.filter(template => template._id !== id);
        state.personalCount = state.personalCount - 1;
        break;
      default:
        console.error(`Attempted to delete Template but ${type} is not a valid Template Type`);
        break;
    }
  },
};

export const actions = {
  async getTemplates({ commit, state }) {
    try {
      const queryStr = new URLSearchParams(state.query).toString();
      const templates = await this.$axios.$get(`${this.$config.producerApiEndpoint}/templates?${queryStr}`);

      for (const type of Object.keys(TemplateType)) {
        commit('setTemplates', { templates: templates[type].templates, type });
        commit('setTemplateCount', { count: templates[type].count, type });
      }
    } catch (err) {
      this.app.$sentry.captureException(err);
      const errorMessage = err?.response?.data?.message || err.message || err;
      console.log('Error fetching templates >>>>>', errorMessage);
      throw new Error(errorMessage);
    }
  },
  async saveTemplate({ rootState }, { name, type }) {
    try {
      return await this.$axios.$post(`${this.$config.producerApiEndpoint}/templates/create`, {
        name,
        type,
        resolution: rootState.video.current.metadata.resolution,
        effects: rootState.video.current.effects,
      });
    } catch (err) {
      this.app.$sentry.captureException(err);
      const errorMessage = err?.response?.data?.message || err.message || err;
      console.log('Error saving template >>>>>', errorMessage);
      throw new Error(errorMessage);
    }
  },
  async updateTemplate({ rootState }, template) {
    try {
      console.log({
        ...template,
        resolution: rootState.video.current.metadata.resolution,
        effects: rootState.video.current.effects,
      });
      return await this.$axios.$post(`${this.$config.producerApiEndpoint}/templates/update`, {
        ...template,
        resolution: rootState.video.current.metadata.resolution,
        effects: rootState.video.current.effects,
      });
    } catch (err) {
      this.app.$sentry.captureException(err);
      const errorMessage = err?.response?.data?.message || err.message || err;
      console.log('Error updating template >>>>>', errorMessage);
      throw new Error(errorMessage);
    }
  },
  loadTemplate({ commit }, template) {
    commit('video/removeAllEffects', null, { root: true });
    template.effects.forEach(effect => {
      commit('video/addEffect', effect, { root: true });
    });
  },
  async deleteTemplate({ commit }, { id, type }) {
    try {
      await this.$axios.$delete(`${this.$config.producerApiEndpoint}/templates/${id}`);
      commit('deleteTemplate', { id, type });
    } catch (err) {
      const errorMessage = err?.response?.data?.message || err.message || err;
      this.app.$sentry.captureException(err);
      console.log('Error deleting template: ', errorMessage);
      throw new Error(errorMessage);
    }
  },
};
